import { COMMENT_SAVING_STATUS, COMMENTS_INPUT_MAX_LENGTH, COMMENTS_INPUT_THRESHOLD_LENGTH } from "utils/constants";
import translate from "components/translations/translations.wrapper";
import React from "react";
import "./draftActionlessComments.scss";
import { DraftCommentsValidationProps } from "types/comments";

const CommentsValidation: React.FC<DraftCommentsValidationProps> = (props) => {
    const characterMaxLimitReached = props.comment?.length > COMMENTS_INPUT_MAX_LENGTH;
    const characterThresholdReached = props.comment?.length >= COMMENTS_INPUT_THRESHOLD_LENGTH && props.comment?.length <= COMMENTS_INPUT_MAX_LENGTH;
    const showCharacterLimitError = characterMaxLimitReached || characterThresholdReached;

    return (
        <div className="draft-comments-length-validation">
            {!props.comment ?
                <div></div> :
                <div>
                    {props.offline?
                        <div className="font-12 text-danger">
                                    <span className="vismaicon vismaicon-sm vismaicon-filled vismaicon-error"
                                          data-tooltip-error={props.translate("taskDetail.commentNotDeliveredTooltip")}
                                    />
                            {props.translate("taskDetail.draftCommentNotSaved")}
                        </div> :
                        <div>
                            {!showCharacterLimitError && (props.status === COMMENT_SAVING_STATUS.START ||
                                    props.status === COMMENT_SAVING_STATUS.IDLE ||
                                    props.status === COMMENT_SAVING_STATUS.ERROR) &&
                                <div className="font-12 text-disabled">
                                    <span className="vismaicon vismaicon-sm vismaicon-filled vismaicon-info mt-0" />
                                    {props.translate("taskDetail.draftCommentCanBeSaved")}
                                </div>
                            }
                            {!showCharacterLimitError && (props.status === COMMENT_SAVING_STATUS.SAVING) &&
                                <div className="font-12 text-disabled">
                                    <span className="vismaicon vismaicon-sm vismaicon-spinner" />
                                    {props.translate("taskDetail.draftCommentUpdating", (COMMENTS_INPUT_MAX_LENGTH - props.comment.length))}
                                </div>
                            }
                            {!showCharacterLimitError && props.status === COMMENT_SAVING_STATUS.DONE &&
                                <div className="font-12 text-disabled">
                                    <span className="vismaicon vismaicon-sm vismaicon-checkmark-dyn-grey" />
                                    {props.translate("taskDetail.draftCommentTextUpdated", (COMMENTS_INPUT_MAX_LENGTH - props.comment.length))}
                                </div>
                            }
                            {characterThresholdReached &&
                                <div className="font-12 text-disabled">
                                    <span className="vismaicon vismaicon-sm vismaicon-filled vismaicon-warning" />
                                    {props.translate("taskDetail.draftCommentsCharactersLeft", (COMMENTS_INPUT_MAX_LENGTH - props.comment.length))}
                                </div>
                            }
                            {characterMaxLimitReached &&
                                <div className="font-12 text-danger">
                                    <span className="vismaicon vismaicon-sm vismaicon-filled vismaicon-error"
                                          data-tooltip-error={props.translate("taskDetail.commentTooLongTooltip")}
                                    />
                                    {props.translate("taskDetail.draftCommentsCharacters", props.comment.length)}
                                </div>
                            }
                        </div>
                    }
                </div>
            }
        </div>
    );
};

const withTranslations = translate(CommentsValidation);

export default withTranslations;