import React, {Component} from 'react';
import _ from 'lodash';
import {DropdownButton, Dropdown} from 'react-bootstrap';
import {
    DOCUMENT_ACTIONS,
    DOCUMENT_DETAILS_URL_PART,
    DOCUMENT_OVERVIEW_STATUS,
    DOCUMENT_TYPE,
    LOADING_STATUS
} from "utils/constants.js";
import {
    DOCUMENT_OVERVIEW,
    DOCUMENT_OVERVIEW_COST_REQUEST,
    DOCUMENT_OVERVIEW_COST_REQUEST_SELECT,
    DOCUMENT_OVERVIEW_EXPENSE_CLAIM,
    DOCUMENT_OVERVIEW_EXPENSE_CLAIM_SELECT,
    DOCUMENT_OVERVIEW_GENERIC,
    DOCUMENT_OVERVIEW_INVOICE,
    DOCUMENT_OVERVIEW_INVOICE_SELECT,
    DOCUMENT_OVERVIEW_LEAVE_REQUEST,
    DOCUMENT_OVERVIEW_LEAVE_REQUEST_SELECT,
    DOCUMENT_OVERVIEW_TIMESHEET,
    DOCUMENT_OVERVIEW_TIMESHEET_SELECT,
    DOCUMENT_OVERVIEW_VOUCHER,
    DOCUMENT_OVERVIEW_VOUCHER_SELECT
} from '../resizableTable/columnsMap';
import * as reduxSelectors from '../store/application.reducers';
import {connect} from 'react-redux';
import {
    changeActiveCounter,
    changeActiveDocType,
    changeActiveDocTypeToDefault,
    changeFilter,
    documentOverviewTaskSelect,
    getDocumentOverview,
    loadMoreOverview,
    preventDocumentReload,
    saveDocumentScrollPosition,
    sortingColumnChanged
} from './documentOverview.action';
import {formatDate, formatDateTime} from "utils/valueFormatter.function.js";
import "./documentOverview.scss";
import 'reactabular-resizable/style.css';
import {ResizableTable} from '../resizableTable/Table.component';
import AsyncTaskActionExecutorDocument from './AsyncTaskActionExecutor.component';
import {userHasAccessRights} from "utils/accessRights.function.js";
import {taskAction} from '../mytasks/action/taskAction.action';
import {pageLoaded} from '../router/route.action';
import translate from '../translations/translations.wrapper.jsx';
import {withRouter} from "components/router/withRouter.tsx";
import {launchDarkly} from "utils/launchDarkly";


const DEFAULT_TAB_FILTER = 0;

export class DocumentOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 1
        };
        this.changeActiveDocType = this.changeActiveDocType.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.getFilters = this.getFilters.bind(this);
        this.loadMoreOverview = this.loadMoreOverview.bind(this);
        this.mapTasks = this.mapTasks.bind(this);
        this.onSelectRow = this.onSelectRow.bind(this);
        this.onRow = this.onRow.bind(this);
        this.refreshGrid = this.refreshGrid.bind(this);
        this.selectTasks = this.selectTasks.bind(this);
    }

    componentDidMount() {
        if (this.props.shouldPreventReload) {
            this.props.preventDocumentReload(false);
            return;
        }
        this.props.getDocumentOverview(this.props.docType, this.props.activeCounter, this.props.filters, this.props.currentContextId);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentContextId !== this.props.currentContextId && userHasAccessRights(this.props.userRoles, this.props.location)) {
            this.props.changeActiveDocTypeToDefault();
            this.props.getDocumentOverview(DEFAULT_TAB_FILTER, DOCUMENT_OVERVIEW_STATUS.BLOCKED, this.props.filters, this.props.currentContextId);
        }

        if (this.props != null && this.props.loadingStatus !== prevProps.loadingStatus && this.props.loadingStatus === LOADING_STATUS.DONE) {
            this.props.pageLoaded();
        }
    }

    changeActiveDocType(type) {
        let numType = parseInt(type);
        this.props.changeActiveDocType(numType);
        this.props.getDocumentOverview(numType, this.props.activeCounter, undefined, this.props.currentContextId);
    }

    getColumns() {
        switch (this.props.docType) {
            case DOCUMENT_TYPE.EXPENSE_CLAIM:
                if (this.props.userRoles.systemAdministrator)
                    return DOCUMENT_OVERVIEW_EXPENSE_CLAIM_SELECT;
                return DOCUMENT_OVERVIEW_EXPENSE_CLAIM;

            case DOCUMENT_TYPE.INVOICE:
                if (this.props.userRoles.systemAdministrator)
                    return DOCUMENT_OVERVIEW_INVOICE_SELECT;
                return DOCUMENT_OVERVIEW_INVOICE;

            case DOCUMENT_TYPE.VOUCHER:
                if (this.props.userRoles.systemAdministrator)
                    return DOCUMENT_OVERVIEW_VOUCHER_SELECT;
                return DOCUMENT_OVERVIEW_VOUCHER;

            case DOCUMENT_TYPE.TIMESHEET:
                if (this.props.userRoles.systemAdministrator)
                    return DOCUMENT_OVERVIEW_TIMESHEET_SELECT;
                return DOCUMENT_OVERVIEW_TIMESHEET;

            case DOCUMENT_TYPE.LEAVE_REQUEST:
                if (this.props.userRoles.systemAdministrator)
                    return DOCUMENT_OVERVIEW_LEAVE_REQUEST_SELECT;
                return DOCUMENT_OVERVIEW_LEAVE_REQUEST;

            case DOCUMENT_TYPE.COST_REQUEST:
                if (this.props.userRoles.systemAdministrator)
                    return DOCUMENT_OVERVIEW_COST_REQUEST_SELECT;
                return DOCUMENT_OVERVIEW_COST_REQUEST;

            default:
                if (this.props.userRoles.systemAdministrator)
                    return DOCUMENT_OVERVIEW_GENERIC;
                return DOCUMENT_OVERVIEW;
        }
    }


    getFilters() {
        let tallies = [];
        Object.keys(DOCUMENT_OVERVIEW_STATUS).forEach(key => {
            tallies[DOCUMENT_OVERVIEW_STATUS[key]] = 0
        });

        if (this.props.counters && this.props.counters.length > 0) {
            this.props.counters.forEach(counter => {
                //for All add all of the talles
                if (this.props.docType === 0) {
                    Object.keys(DOCUMENT_OVERVIEW_STATUS).forEach(key => {
                        tallies[DOCUMENT_OVERVIEW_STATUS[key]] += counter.counters[DOCUMENT_OVERVIEW_STATUS[key]];
                    });
                } else {
                    if (counter.documentType === this.props.docType) {
                        Object.keys(DOCUMENT_OVERVIEW_STATUS).forEach(key => {
                            tallies[DOCUMENT_OVERVIEW_STATUS[key]] = counter.counters[DOCUMENT_OVERVIEW_STATUS[key]];
                        });
                    }
                }
            });
        }

        let result = Object.keys(DOCUMENT_OVERVIEW_STATUS).map((key, index) => {
            let tallie = " ";
            if (DOCUMENT_OVERVIEW_STATUS[key] === DOCUMENT_OVERVIEW_STATUS.BLOCKED)
                tallie = (tallies && tallies[DOCUMENT_OVERVIEW_STATUS[key]] > 0) ? <span
                    className="badge badge-danger margin-left-8">{tallies[DOCUMENT_OVERVIEW_STATUS[key]]}</span> : "";
            else if (DOCUMENT_OVERVIEW_STATUS[key] === DOCUMENT_OVERVIEW_STATUS.IN_PROGRESS) {
                tallie = tallies ? <span
                    className="badge badge-success margin-left-8">{tallies[DOCUMENT_OVERVIEW_STATUS[key]]}</span> : "";
            }
            return (
                <button type="button"
                        key={key}
                        className={"btn btn-default " + ((DOCUMENT_OVERVIEW_STATUS[key] === this.props.activeCounter) ? "active" : '')}
                        onClick={this.props.changeActiveCounter.bind(null, this.props.docType, DOCUMENT_OVERVIEW_STATUS[key], this.props.filters, this.props.currentContextId)}>
                    {this.props.translate("documentOverview.status." + key)}{tallie}
                </button>
            );
        });
        return result;
    }

    loadMoreOverview(callObject) {
        this.props.loadMoreOverview(callObject, this.props.processType, this.props.activeCounter, this.props.currentContextId);
    }

    mapTasks(tasks, selecteTasks) {

        let newTaskList = _.cloneDeep(tasks);
        let taskList = newTaskList.rows;
        newTaskList.rows = taskList ? taskList.map((task, index) => {
            let foudTask = _.findIndex(selecteTasks, (x => x === task.key));
            task.isSelected = foudTask !== -1;
            task.taskActivatedDate = formatDateTime(task.taskActivatedDate);
            task.taskDueDate = formatDate(task.taskDueDate);
            task.dueDate = formatDate(task.dueDate);
            task.invoiceDate = formatDate(task.invoiceDate);
            task.date = formatDateTime(task.date);
            task.processCreatedDate = formatDateTime(task.processCreatedDate);
            // reactabular needs a unique id for each row
            task.index = index;
            return task;
        }) : [];

        return newTaskList;
    }

    onSelectRow(key) {
        this.props.history(DOCUMENT_DETAILS_URL_PART + key);
    }

    onRow(task) {
        return {
            onClick: () => this.onSelectRow(task.key)
        }
    }

    refreshGrid(objfilter) {
        let newFilter = this.props.filters;
        if (objfilter)
            newFilter = Object.assign({}, this.props.filters, objfilter);
        this.props.getDocumentOverview(this.props.docType, this.props.activeCounter, newFilter, this.props.currentContextId);
    }

    selectTasks(tasksIndex) {
        if (this.props.tasks.rows.length > 0) {
            let selectedTasks = [];
            if (tasksIndex.length > 0) {
                let tasksIds = this.props.tasks.rows.map((task) => {
                    return task.key;
                });
                selectedTasks = selectedTasks.concat(tasksIds);
            } else {
                selectedTasks = selectedTasks.concat(this.props.tasks.rows[tasksIndex].key)
            }
            this.props.documentOverviewTaskSelect(selectedTasks);
        }
    }

    render() {
        let mappedColumns = this.getColumns();
        let filters = this.getFilters();
        let selector_values = Object.assign({}, {"documentType": this.props.companies});
        let mappedTasks = this.mapTasks(Object.assign({}, this.props.tasks), this.props.selectedTasks);

        let docTypeComboValues = [];
        if (this.props.companies.length > 1) {
            docTypeComboValues.push(
                <Dropdown.Item key={0} eventKey={DEFAULT_TAB_FILTER} onSelect={this.changeActiveDocType}>
                    {this.props.translate("documentOverview.all")}
                </Dropdown.Item>
            );
        }

        this.props.companies.forEach((company, index) => {
            docTypeComboValues.push(<Dropdown.Item key={company} eventKey={company}
                                                   onSelect={this.changeActiveDocType}>
                {this.props.translate("documentType." + DOCUMENT_TYPE.asString(company))}
            </Dropdown.Item>);
        });

        let docTypeTitle = this.props.docType > 0 ? this.props.translate("documentType." + DOCUMENT_TYPE.asString(this.props.docType))
            : this.props.translate("documentOverview.all");

        return (
            <div className="d-table document-overview">
                <AsyncTaskActionExecutorDocument translate={this.props.translate} reloadTasks={this.refreshGrid}/>
                <div className="d-table-row">
                    <div
                        className="d-table-cell filter_name pe-5">{this.props.translate("documentOverview.documentType")}</div>
                    <div className="d-table-cell dropdown">
                        <DropdownButton
                            title={docTypeTitle}
                            bsPrefix="btn btn-default"
                            className="text-start ml-1 col-md-3 px-0"
                            id="Doc-overview-dropdown"
                            onSelect={this.changeActiveDocType}
                            key={1}>
                            {docTypeComboValues}
                        </DropdownButton>
                    </div>
                </div>


                <div className="d-table-row">
                    <div className="d-table-cell filter_name pe-5 align-top">
                        {this.props.translate("documentOverview.documentStatus")}
                    </div>
                    <div className="d-table-cell btn-toolbar btn-toolbar-primary">
                        <div className="btn-group"
                             role="group">
                            {filters}
                        </div>
                    </div>
                </div>
                <div className="mt-5">
                    <ResizableTable tasks={mappedTasks}
                                    defaultColumns={mappedColumns}
                                    selectedTasks={this.props.selectedTasks}
                                    activeCounter={this.props.activeCounter}
                                    loadingStatus={this.props.loadingStatus}
                                    selectors={selector_values}
                                    sortingLogic={this.props.sortingLogic}
                                    filterChanged={this.props.changeFilter}
                                    sortingColumnChanged={this.props.sortingColumnChanged}
                                    taskSelect={this.selectTasks}
                                    translate={this.props.translate}
                                    filters={this.props.filters}
                                    onRow={this.onRow}
                                    loadMoreTasks={this.loadMoreOverview}
                                    refreshGrid={this.refreshGrid}
                                    saveScrollPosition={this.props.saveDocumentScrollPosition}
                                    scrollTop={this.props.scrollTop}
                    />
                </div>


                {
                    <OverviewButtons selectedTaskKeys={this.props.selectedTasks}
                                     counter={this.props.activeCounter}
                                     userRole={this.props.userRoles}
                                     translate={this.props.translate}
                                     action={this.props.taskAction}/>
                }
            </div>

        )
    }
}

const withTranslations = translate(DocumentOverview);

const DocumentOverviewWithLocation = withRouter(withTranslations);

const mapStateToProps = function (store) {
    return {
        activeCounter: reduxSelectors.getDocumentOverviewActiveCounter(store),
        counters: reduxSelectors.getDocumentOverviewCounters(store),
        companies: reduxSelectors.getDocumentOverviewCompanies(store),
        currentContextId: reduxSelectors.getUsersCurrentContextId(store),
        docType: reduxSelectors.getDocumentOverviewDocumentType(store),
        filters: reduxSelectors.getDocumentOverviewFilter(store),
        loadingStatus: reduxSelectors.getDocumentOverviewLoadingStatus(store),
        processType: reduxSelectors.getDocumentOverviewDocumentType(store),
        sortingLogic: reduxSelectors.getDocumentOverviewSortingLogic(store),
        selectedTasks: reduxSelectors.getDocumentOverviewSelectedTasks(store),
        tasks: reduxSelectors.getDocumentOverviewTasks(store),
        userRoles: reduxSelectors.getUsersRoles(store),
        shouldPreventReload: reduxSelectors.shouldPreventDocumentReload(store),
        scrollTop: reduxSelectors.getDocumentScrollTop(store)
    };
};
const connected = connect(mapStateToProps, {
    changeActiveCounter,
    changeActiveDocType,
    changeActiveDocTypeToDefault,
    documentOverviewTaskSelect,
    changeFilter,
    getDocumentOverview,
    loadMoreOverview,
    sortingColumnChanged,
    taskAction,
    preventDocumentReload,
    saveDocumentScrollPosition,
    pageLoaded
})(DocumentOverviewWithLocation);

export default connected;


const OverviewButtons = ({selectedTaskKeys, action, translate, counter, userRole}) => {

    let btnClass = "btn btn-default ";
    let disabledClass = "disabled";
    let emailClass = "float-right " + (selectedTaskKeys.length === 1 ? btnClass : btnClass + disabledClass);
    let genericClass = "float-right " + (selectedTaskKeys.length >= 1 ? btnClass : btnClass + disabledClass);

    const cancelDocumentsEnabled =  launchDarkly.companyClient?.variation("enable-cancel-documents", false);

    return (
        <div className="row fixed-bottom mb-3 px-32">
            <div className="col-md-12 float-right px-3">
                {userRole.systemAdministrator &&
                    <div>
                        <button type="btn" className={emailClass + " btn-margin-left"}
                                onClick={() => {
                                    action(DOCUMENT_ACTIONS.EMAIL, selectedTaskKeys);
                                }}>
                            {translate("documentOverview.actions.email")}
                        </button>
                    </div>
                }
                {counter === DOCUMENT_OVERVIEW_STATUS.BLOCKED && userRole.systemAdministrator &&
                    <div>
                        {userRole.approver &&
                            <div>
                                {cancelDocumentsEnabled &&
                                    <button type="btn"
                                            className={emailClass + " btn-margin-right"}
                                            onClick={() => {
                                                action(DOCUMENT_ACTIONS.CANCEL, selectedTaskKeys);
                                            }}>
                                        {translate("documentOverview.actions.cancel")}
                                    </button>
                                }
                                <button type="btn" className={genericClass + " btn-margin-right"}
                                        onClick={() => {
                                            action(DOCUMENT_ACTIONS.REJECT_DIRECTLY, selectedTaskKeys);
                                        }}>
                                    {translate("documentOverview.actions.rejectDirectly")}
                                </button>
                            </div>
                        }
                        <button type="btn" className={genericClass + " btn-margin-right"}
                                onClick={() => {
                                    action(DOCUMENT_ACTIONS.SEND_FOR_APPROVAL, selectedTaskKeys);
                                }}>
                            {translate("documentOverview.actions.sendForApproval")}
                        </button>
                        {userRole.approver &&
                            <button type="btn" className={genericClass + " btn-margin-right"}
                                    onClick={() => {
                                        action(DOCUMENT_ACTIONS.APPROVE_DIRECTLY, selectedTaskKeys);
                                    }}>
                                {translate("documentOverview.actions.approveDirectly")}
                            </button>
                        }
                    </div>
                }
            </div>
        </div>
    )
};