import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as reduxSelectors from "components/store/application.reducers";
import { changeUnsavedComment, taskCommentStartSaving } from "components/taskdetails-remake/taskDetails.action";
import { ActionlessCommentsProps } from "types/comments";
import translate from "components/translations/translations.wrapper";
import CommentsPanelComponent from "components/taskdetails-remake/panels/draftcomments/CommentsPanel.component";
import { COMMENT_SAVING_STATUS } from "utils/constants";
import { snowplowTrackEvent } from "utils/snowplow";
import { Store } from "redux";

const DraftActionlessComments = (props: ActionlessCommentsProps) => {
    const [currentComment, setCurrentComment] = useState<string | null>(null);
    const [offline, setOffline] = useState(false);
    const commentsCount = useSelector(reduxSelectors.getTaskCommentsCount);
    const isLoading = useSelector(reduxSelectors.getTaskCommentsLoadingStatus);
    const taskUnsavedComment = useSelector(reduxSelectors.getTaskUnsavedComment);
    const savingCommentStatus = useSelector(reduxSelectors.getTaskCommentSavingStatus);
    const userData = useSelector((store: Store) => reduxSelectors.getUsersData(store));
    const [saveTimeout, setSaveTimeout] = useState(undefined);
    const dispatch = useDispatch();

    useEffect(() => {
        requestData(props.taskId);
    }, [props.taskId]);

    useEffect(() => {
        if (currentComment !== taskUnsavedComment)
            setCurrentComment(taskUnsavedComment);
    }, [taskUnsavedComment]);

    useEffect(() => {
        if (savingCommentStatus === COMMENT_SAVING_STATUS.START && currentComment) {
            if (saveTimeout !== undefined) {
                clearTimeout(saveTimeout);
            }
            addNewComment();
        }
    }, [savingCommentStatus]);

    useEffect(() => {
        const search = setTimeout(() => {
            updateTaskUnsavedComment(currentComment);
        }, 1000);
        return () => clearTimeout(search);
    }, [currentComment]);

    const requestData = (taskId: string) => {
        if (props.getCommentsHandler)
            props.getCommentsHandler(taskId);
    };

    const addNewComment = () => {
        if (!navigator.onLine) {
            setOffline(true);
            setSaveTimeout(setTimeout(addNewComment, 1000));
            return;
        } else {
            setOffline(false);
        }
        if (props.addCommentHandler) {
            props.addCommentHandler(props.taskId, currentComment);
        }
    };

    const updateTaskUnsavedComment = (comment: string) => {
        if (comment === taskUnsavedComment) {
            return;
        }

        dispatch(changeUnsavedComment(comment));
    };

    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        let comment = event.target.value;

        if (comment !== currentComment) {
            setCurrentComment(comment);
        }
    };

    const addComment = () => {
        snowplowTrackEvent({
            category: "task_detail_" + props.taskDetailsType,
            action: "add_actionless_comment",
            label: "add_comment",
        }, userData);
        dispatch(taskCommentStartSaving());
    }

    const currentCommentsCount = isLoading ? "" : commentsCount;


    return <CommentsPanelComponent
        readOnly={props.readOnly}
        commentsCount={currentCommentsCount}
        comment={currentComment}
        savingCommentStatus={savingCommentStatus}
        automaticSave={false}
        offline={offline}
        onChange={onChange}
        onAddComment={addComment}
        allowComments = {true}/>
};

const withTranslations = translate(DraftActionlessComments);

export default withTranslations;